<template>
  <v-card>
    <v-card-title>Eventos Cadastrados<v-spacer></v-spacer> </v-card-title>
    <v-card-text>
      <DataTable :headers="header" :items="eventos">
        <template slot="item.color" slot-scope="{ item }">
          <v-chip block :color="item.color" class="d-block text-center">{{ item.color }}</v-chip>
        </template>
        <template slot="item.showEvent" slot-scope="{ item }">
           {{ item.showEvent ? "Sim" : "Não" }}
        </template>
        <template slot="item.actions" slot-scope="{ item }">
          <!-- botão editar -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="editar(item)"
                color="warning"
                class="mr-1"
              >mdi-pencil</v-icon>
            </template>
            <span>Editar : {{ item.name }}</span>
          </v-tooltip>
          <!-- botão excluir -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="excluir(item)"
                color="red"
              >mdi-trash-can</v-icon>
            </template>
            <span>Excluir : {{ item.name }}</span>
          </v-tooltip>
        </template>

      </DataTable>
       </v-card-text>

    <!-- dialog de cadastro -->
    <v-dialog v-model="dialog" persistent max-width="700" scrollable>
      <v-card>
        <v-card-title class="text-h5 pr-4 mb-2"
          >{{ evento.id ? "Editar" : "Cadastrar" }} Evento<v-spacer></v-spacer>
          <v-btn icon @click="clear">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-1 pb-0">
          <v-form ref="formDispositivo" :disabled="!evento.enabled">
            <v-row>
              <v-col class="pb-0">
                <v-text-field
                  v-model="evento.name"
                  label="Nome do evento"
                  hint="Nome que será exibido no evento, na tela de monitoramento"
                  required
                  :rules="requiredField"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-text-field
                  v-model="evento.code"
                  label="Código do evento no sistema"
                  required
                  :rules="requiredField"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col class="py-0">
                <v-text-field
                  v-model="evento.color"
                  label="Cor da borda do evento"
                  outlined
                  type="color"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-switch
                  class="d-inline-block pt-0"
                  v-model="blink"
                  label="Piscar fundo constantemente"
                ></v-switch>
              </v-col>
              <v-col class="py-0">
                <v-text-field
                  v-model="evento.blinkColor"
                  label="Cor do fundo piscante"
                  outlined
                  type="color"
                  :disabled="!blink"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
            <v-switch
              class="d-inline-block pt-0 mt-0"
              v-model="evento.showEvent"
              label="Exibir no monitoramento"
            ></v-switch>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn text @click="clear">Cancelar</v-btn>
          <v-btn color="success" @click="save"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- botão de cadastrar -->
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="amber darken-1"
          dark
          absolute
          bottom
          right
          fab
          fixed
          class="mb-10"
          v-bind="attrs"
          v-on="on"
          @click="dialog = true"
        >
          <v-icon color="black">mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Adicionar Dispositivo</span>
    </v-tooltip>
    <ConfirmDialog ref="confirmDialog" />
  </v-card>
</template>

<script>
import { requiredField } from "../../Utils/InputValidation";
import DataTable from "@/components/DataTableGeneric.vue";
import ConfirmDialog from "@/components/ConfirmDialog";
export default {
  name: "EventTypes",
  components: {
    DataTable,
    ConfirmDialog,
  },
  data: () => {
    return {
      dialog: false,
      evento: {
        id: null,
        enabled: true,
        showEvent: true,
      },
      eventos: [],
      header: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "name" },
        { text: "Codigo", value: "code" },
        { text: "Cor", value: "color", sortable: false},
        { text: "Exibindo", value: "showEvent", sortable: false},
        {
          text: "Ações",
          value: "actions",
          sortable: false,
          filterable: false,
          width: 85,
        },
      ],
      requiredField,
      blink: false,
    };
  },
  methods: {
    getEvents() {
      this.$http("/eventType").then((resp) => {
        this.eventos = resp.data;
      });
    },
    save() {
      if (!this.$refs.formDispositivo.validate()) return;
      delete this.evento.createdAt;
      delete this.evento.updatedAt;
      if (!this.blink)
        this.evento.blinkColor = null;
      const metodo = this.evento.id ? "put" : "post";
      const finalUrl = this.evento.id ? `/${this.evento.id}` : "";
      this.$http[metodo](`/eventType${finalUrl}`, this.evento).then(() => {
        this.getEvents();
        this.clear();
        this.$store.dispatch("resetSnack");
        this.$store.dispatch("showSuccessSnack", "Evento salvo com sucesso");
      });
    },
    editar(rec) {
      this.evento = rec;
      this.blink = !!rec.blinkColor;
      this.dialog = true;
    },
    excluir(item) {
      this.$refs.confirmDialog.open({
        title: "Excluir evento?",
        text: `Deseja excluir o evento <strong>${item.name}</strong>?`,
        confirmCallback: () => {
          this.$http.delete(`/eventType/${item.id}`).then(() => {
            this.get();
            this.$store.dispatch("resetSnack");
            this.$store.dispatch(
              "showSuccessSnack",
              "Evento deletado com sucesso"
            );
          });
        },
      });
    },
    clear() {
      this.evento = {
        id: null,
        enabled: true,
      };
      this.dialog = false;
      this.$refs.formDispositivo.resetValidation();
    },
  },
  mounted() {
    this.getEvents();
  },
};
</script>

<style>

</style>